// colors
$themeColor: #d66000;



$primary: $themeColor;
$light: #ffad33;


$theme-colors: (
);

$custom-colors: (
  "primary": $primary,
  "light": $light
);
$bg-success-light : rgba(105, 195, 87, 0.5);
;
$bg-danger-light : rgba(240, 55, 4, 0.5);
$bg-warning-light : rgba(207, 186, 15, 0.5);

.bg-success-light {
  background-color: $bg-success-light;
}

.bg-danger-light {
  background-color: $bg-danger-light;
}

.bg-warning-light {
  background-color: $bg-warning-light;
}

$theme-colors: map-merge($theme-colors, $custom-colors);

table.table-bordered {
  border: 1px solid $primary;
  margin-top: 20px;
}

table.table-bordered tr>th,
table.table-bordered tr>td {
  border: 1px solid $primary;
}

table.table-bordered thead>tr>td {
  border: 1px solid #cecece;
  border-bottom: 1px solid $primary;
}

table.table-bordered thead>tr>td:nth-child(2n) {
  border: 1px solid $primary;
}

.td-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

app-page-not-found{
  display: flex;
  height: 100%;
}
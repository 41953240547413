@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');

.barcode {
  font-family: barcode;
}

#container {
  min-height: 100%;
  position: relative;
}

#body {
  display: block;
  padding-bottom: 85px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  // height: 34px;
  background-color: $primary;
  display: flex;
  align-items: center;
}


a,
a:focus,
a:hover {
  text-decoration: none;
}

hr.light {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.blue-gradient {
  background: radial-gradient(#44309f, #0171ad);
}

.orange-gradient {
  background: radial-gradient(#d66000, #e49759)
}

.black-gradient {
  background: radial-gradient(#000000, #545454);
}

// Added for Capex
.itg-gradient {
  background: radial-gradient(#d66000, #e49759) !important;
  color: white !important;
  font-family: system-ui;
}

.font-size {
  font-size: 15px;
}

.pd-0{
  padding:0px
}
// end

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.vertical-align-unset {
  vertical-align: unset;
}

;

nav.navbar {
  a {
    // color: $white;
    transition: all ease-in-out 0.3;
  }

  li.active,
  li:hover {
    a {
      background-color: $primary;
      color: $white;
    }
  }
}


// sidebar 
.msb {
  width: 50px;
  position: fixed;
  left: 0;
  top: 56px;
  right: auto;
  overflow-y: auto;
  white-space: nowrap;
  z-index: 1;
  transition: ease-in-out 300ms;
  background-color: $white;
  bottom: 0px;

  .navbar {
    border: none;
    margin-left: 0;
    background-color: inherit;
  }

  .navbar-header {
    width: 100%;
    border-bottom: 1px solid $white;
    margin-bottom: 20px;
  }

  .navbar-nav {
    .panel {
      border: 0 none;
      box-shadow: none;
      margin: 0;
      background: inherit;
      border-top: 1px solid $black;
    }

    li {
      display: block;
      width: 100%;

      &.active,
      &:hover {
        a {
          background-color: $primary;
          color: $white;
          transition: ease-in-out;
        }

        ul {
          li {
            a {
              background-color: $white;
              color: $black;
            }

            &.active,
            &:hover {
              a {
                background-color: $primary;
                color: $white;
                transition: ease-in-out;
              }
            }

          }
        }
      }

      .link-text {
        font-size: 15px;
        white-space: normal;
        display: none;
        transition: ease-in-out 300ms;
      }

      .caret {
        display: none;
      }

      .glyphicon,
      .fa {
        transition: ease-in-out 300ms;
      }


      a {
        font-size: 20px;
        color: $black;
        display: flex;
        padding: 10px 15px;
        transition: ease-in-out;
        align-items: center;
        justify-content: center;
      }

      ul li a {
        .link-text {
          font-size: 15px;
          text-indent: 60px;
        }
      }
    }

  }

  .nb {
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 30px;
    overflow: hidden;
  }
}

.mcw {
  margin-left: 50px;
  position: relative;
  min-height: 100vh;
  padding-top: 56px;
  transition: ease-in-out 300ms;
  height: 100%;
}

.slide {
  .msb {
    transition: ease-in-out 300ms;
    width: 300px;

    .navbar-nav li {
      a {
        justify-content: unset;

        .glyphicon,
        i.fa {
          margin-right: 5px;
          width: 23px;
          transition: ease-in-out 300ms;
        }

        .link-text,
        .caret {
          display: block;
        }

      }

      ul {
        li {
          a {
            text-indent: 10px;

            .link-text {
              text-indent: 0px;
              padding-left: 16px;
            }
          }
        }
      }
    }
  }

  .mcw {
    margin-left: 300px;
  }
}

.loader {
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 9999;
  position: absolute;
}

.full-loader {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.full-loader .mat-mdc-progress-spinner circle {
  stroke: #d66000 !important;
}

.dummy-textbox {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #e9ecef;
  opacity: 1;
}

// -------------- material ----------
.mat-tab-label-active.mat-tab-label {
  color: $white;
  opacity: 1;
  background-color: $primary;
  font-size: 1rem;
}

.py-6 {
  padding-bottom: 4rem !important;
  padding-top: 4rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

th.mat-header-cell {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

app-title p {
  font-size: 24px;
}

.scroll-table {
  overflow: hidden;
  max-height: 400px;
  overflow-y: auto;
}

.loginForm {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  mat-card {
    width: 400px;
  }
}

button i {
  margin-right: 5px;
}

.dataTable {
  max-height: 60vh;
  overflow: auto;

  table {
    white-space: nowrap;
    margin-bottom: 0px;
  }

}

.dataTable input[type="text"] {
  min-width: 150px;
}

.mr-2 {
  margin-right: 2%;
}

.my-error-wrapper .mat-mdc-form-field-error-wrapper{
  padding: 0px
} 
//Prod
// hr.dark {
//   border-top: 2px solid rgb(0, 150, 214);
// }

//ITG
hr.dark {
  border-top: 2px solid #db6a00ad;
}
.no-scroll {
  overflow: hidden !important;
}
// .mat-mdc-dialog-surface,
// .mdc-dialog__surface {
//     overflow: hidden !important;
// }

.custom-form-field 
.mat-mdc-form-field-infix {
    padding: 6px !important;

}

.custom-form-field 
.mat-mdc-text-field-wrapper
.mdc-text-field{
  padding-left: 0px !important;
}

.custom-form-field 
.mat-mdc-form-field-infix {
    padding: 6px !important;

}


@media (max-width: 768px) {
    #footer {
        height: auto;
        min-height: 75px;
        ;
    }

    .slide {
        .msb {
            transition: ease-in-out 300ms;
            width: 100%;
        }

        .mcw {
            margin-left: 50px;
        }
    }
}